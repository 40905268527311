import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../pages/Services/general.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-page-header',
  templateUrl: './page-header.component.html',
  styleUrls: ['./page-header.component.scss']
})
export class PageHeaderComponent implements OnInit {
  categories: any=[];
  autoCompleteText: any;
  selectedItem: any = 'Home';
  ProductsSpecifications: any[];
  items: any[];
  FilterSpecData: any;
  filteredSpecifications: any[];
  SpecID: any;
  SpecName: any;
  Productsdata: any;
  ChemicalName: any;

  constructor(public generalService: GeneralService, public router: Router) { }

  ngOnInit(): void {
    this.getProductCategories();
  }
  clearValue() {    
    this.Productsdata = "";
    this.filteredSpecifications = [];
    this.ChemicalName = '';
  }
  GetSpecdata(filteredSpecifications) {
    debugger
    this.Productsdata;
    this.autoCompleteText = '';
    localStorage.removeItem('Cattegoryid');
    localStorage.removeItem('CatlogID');
    localStorage.setItem("CatlogID", filteredSpecifications.Catalog_No)
    localStorage.removeItem('ProdDetails');
    localStorage.setItem("ProdDetails", JSON.stringify(filteredSpecifications))
    localStorage.setItem("Cattegoryid", filteredSpecifications.CategoryID);
    var route = this.router.url.substring(0, 14)
   // var sub = route.substring(0,14)
    this.filteredSpecifications = [];
    this.Productsdata = "";
    this.ChemicalName = '';
    var str = filteredSpecifications.ChemicalName.replace(/\s/g, '')
    if (route == '/products-info') {
      localStorage.setItem("Cattegoryid", filteredSpecifications.CategoryID);
      window.location.reload();
    }
    else {      
      localStorage.setItem("Cattegoryid", filteredSpecifications.CategoryID);
      this.router.navigate(['/products-info', filteredSpecifications.P_ID, str]);     
    }

  }
  filterSpecifications(event) {
    debugger
    //in a real application, make a request to a remote url with the query and return filtered results, for demo we filter at client side
    let filtered: any[] = [];
    let query = event.query;

    var UploadFile = new FormData();
    UploadFile.append("name", query);
    var url = "/api/Product/GettingsearchProductCatlogandcasandchemicalname";
    debugger
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      this.filteredSpecifications = [];
      this.filteredSpecifications = data
    }, err => {
      // this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });


  }


  FilterList(id) {
    debugger

    localStorage.removeItem('catid');
    localStorage.setItem("catid", id);
    var route = this.router.url
    if (route == '/Products') {
      window.location.reload();
    }
    else {
      this.router.navigateByUrl('/Products');

    }

  }

  listClick(val) {
    this.selectedItem = val;
  }
  //=================u8ij===New code======================//
 
  getProductCategories() {
    var url = "api/Values/getProductCategories";
    this.generalService.GetData(url).then((data: any) => {
      debugger
        this.categories = data;
    });
  }


}
