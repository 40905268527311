import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { GeneralService } from '../../pages/Services/general.service'


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.scss']
})
export class CareersComponent implements OnInit {

  registerForm: FormGroup;
  SamplePdfPath: any;
  imagefile: any;
  flag: any;
  HomeURL: any;
  Sampelpdf: any;

  constructor(private formBuilder: FormBuilder, public generalService: GeneralService, ) { }

  ngOnInit() {
    this.registerForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      Qualification: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone_number: ['', [Validators.required, Validators.minLength(10), Validators.maxLength(10)]],

    });
  }


  validatepri(e) {
    let input;
    input = String.fromCharCode(e.which);
    return !!/[0-9,.]/.test(input);
  }



  onSubmit() {
    debugger;
    if (this.registerForm.invalid && this.SamplePdfPath == "") {
      return;
    }
    else {
      let arr = [];
      arr.push({
        PersonName: this.registerForm.value.firstName,
        MailId: this.registerForm.value.email,
        PhoneNumber: this.registerForm.value.phone_number,
        Comments: this.registerForm.value.Qualification,
        document: this.SamplePdfPath
      })
      var url = "/api/Product/SendCareerstoadmin";
      debugger
      // this.spinner.show();
      var UploadFile = new FormData();
      UploadFile.append("userdata", JSON.stringify(arr));
      this.generalService.PostData(url, UploadFile).then((data: any) => {
        if (data != null) {
          if (data == "SUCCESS") {
            this.registerForm.reset();
            this.SamplePdfPath = "";
            this.Sampelpdf = "";
            this.imagefile = "";
            this.generalService.ShowAlert('SUCCESS', 'You have successfully submitted your application ', 'SUCCESS')
          }
          else {
            this.generalService.ShowAlert('WARNING', 'something went wrong.', 'warning')
          }
        }
      }, error => ({
        title: 'ERROR',
        text: 'Something went wrong. Please try again later.',
        type: 'error',
        confirmButtonText: 'Ok'

      })
      )
    }

  }


  fileChange1(input) { // For Customer Image
    debugger
    const reader = new FileReader();
    if (input.files.length) {
      debugger
      const file = input.files[0];
      reader.onload = () => {
        debugger
        this.imagefile = reader.result;
      }
      reader.readAsDataURL(file);
      var selectedFile = file;
      var idxDot = selectedFile.name.lastIndexOf(".") + 1;
      var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
      if (extFile == "jpg" || extFile == "jpeg" || extFile == "png" || extFile == ".txt" || extFile == "pdf" || extFile == "xlsx" || extFile == "xlx" || extFile == ".doc") {
        debugger
        var UploadFile = new FormData();
        UploadFile.append("PostedFile", file);
        var url = '/api/Product/Post_DocumentsUpload';
        this.generalService.PostData(url, UploadFile).then((data: any) => {

          if (data != null) {
            debugger
            this.imagefile = data;

            this.SamplePdfPath = data;

          }
        });
      }
      else {
        alert("Only doc/docx, pdf files are allowed!");
      }

    }
  }

  //fileChange1(input2) { // For Customer Image
  //  debugger
  //  this.Sampelpdf = '';
  //  this.SamplePdfPath = '';
  //  debugger
  //  const reader = new FileReader();
  //  if (input2.files.length) {
  //    debugger
  //    const file = input2.files[0];
  //    this.SamplePdfPath = input2.files[0];
  //    reader.onload = () => {
  //      debugger
  //      this.imagefile = reader.result;
  //    }
  //    reader.readAsDataURL(file);
  //    var selectedFile = file;
  //    var idxDot = selectedFile.name.lastIndexOf(".") + 1;
  //    var extFile = selectedFile.name.substr(idxDot, selectedFile.name.length).toLowerCase();
  //    if (extFile != "") {
  //      debugger
  //     // if (this.flag == 2) {
  //        var UploadFile = new FormData();
  //        UploadFile.append("PostedFile", file);
  //        var url = '/api/Product/UploadPdf';
  //         this.generalService.PostData(url, UploadFile).then((data: any) => {
  //        //this.http.post(url, UploadFile).subscribe((data: any) => {
  //          if (data != null) {
  //            debugger
  //            //this.file = data;
  //            //this.SamplePdf= this.HomeURL + data;
  //            this.SamplePdfPath = data;
  //            //this.ProductForm.controls['ProductImage'].setValue(this.imagefile);
  //          }
  //        });
  //      //}
  //    }
  //    else {
  //      alert("Only pdf files are allowed!");
  //    }

  //  }
  //}

}
