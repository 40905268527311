import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
import { AccordionModule } from 'primeng/accordion';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};
import { RouterModule } from '@angular/router';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader'; 
export function HttpLoaderFactory(httpClient: HttpClient) { 
  return new TranslateHttpLoader(httpClient, './assets/i18n/', '.json');
}

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MultiselectDropdownModule } from 'angular-2-dropdown-multiselect';
import { AgmCoreModule } from '@agm/core';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns'; 

import { ToastrModule } from 'ngx-toastr';
import { PipesModule } from './theme/pipes/pipes.module';

import { AppRoutingModule } from './app.routing';
import { AppSettings } from './app.settings';

import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { HeaderComponent } from './theme/components/header/header.component';
import { FooterComponent } from './theme/components/footer/footer.component';
import { SidebarComponent } from './theme/components/sidebar/sidebar.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { BreadcrumbComponent } from './theme/components/breadcrumb/breadcrumb.component';
import { BackTopComponent } from './theme/components/back-top/back-top.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { SideChatComponent } from './theme/components/side-chat/side-chat.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';

import { TableModule      } from 'primeng/table';
import { TreeTableModule  } from 'primeng/treetable';


import { AutoCompleteModule } from 'primeng/autocomplete';
import { DummyComponent } from './pages/dummy/dummy.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PageFooterComponent } from './page-layout/page-footer/page-footer.component';
import { PageHeaderComponent } from './page-layout/page-header/page-header.component';
import { HomeComponent } from './page-layout/home/home.component';
import { ProductsComponent } from './page-layout/products/products.component';
import { ProductinfoComponent } from './page-layout/productinfo/productinfo.component';
import { AboutUsComponent } from './page-layout/about-us/about-us.component';
import { CareersComponent } from './page-layout/careers/careers.component';
import { ContactUsComponent } from './page-layout/contact-us/contact-us.component';
import { ClientsComponent } from './page-layout/clients/clients.component'
import { TechSupportComponent } from './page-layout/tech-support/tech-support.component'
import { PhotogalleryComponent } from './page-layout/photogallery/photogallery.component'
import { GalleriaModule } from 'primeng/galleria';
import { NewsComponent } from './page-layout/news/news.component';
//import { TestComponent } from './page-layout/test/test.component';
//import { NgxImageZoomModule } from 'ngx-image-zoom';


@NgModule({  
  imports: [
   // NgxImageZoomModule.forRoot(),
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    PerfectScrollbarModule,
    NgbModule,
    MultiselectDropdownModule, TableModule, TreeTableModule,  
    AgmCoreModule.forRoot({   
      apiKey: 'AIzaSyAO7Mg2Cs1qzo_3jkKkZAKY6jtwIlm41-I'
    }),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory
    }),
    ToastrModule.forRoot(), 
    PipesModule,
    AppRoutingModule, GalleriaModule,
    HttpClientModule, AutoCompleteModule, RouterModule, AccordionModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [
    AppComponent,
    PagesComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    BreadcrumbComponent,
    BackTopComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    FlagsMenuComponent,
    SideChatComponent,
    FavoritesComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    DummyComponent,
    PageLayoutComponent,
    PageFooterComponent,
    PageHeaderComponent,
    HomeComponent,
    ProductsComponent,
    ProductinfoComponent,
    AboutUsComponent,
    ContactUsComponent,
    CareersComponent,
    ClientsComponent,
    TechSupportComponent,
    PhotogalleryComponent,
    NewsComponent,
   // TestComponent,
  ],
  providers: [
    AppSettings,
    {
      provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG,
    }
   // Location, { provide: LocationStrategy, useClass: PathLocationStrategy }
  ],
  bootstrap: [ AppComponent ]
})
export class AppModule { } 
