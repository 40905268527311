<div class="d-flex justify-content-start favorites-container ml-5" [class.show-list]="toggle">
    <ul *ngIf="favorites.length == 0" class="list-inline mb-0">
        <li class="list-inline-item"><span class="fs-13 font-italic">Choose favorites <i id="arrow" class="fa fa-long-arrow-right"></i></span></li>
    </ul> 
    <ul *ngIf="favorites.length > 0" class="list-inline mb-0">
        <li *ngFor="let favorite of favorites" class="list-inline-item" placement="bottom" [ngbTooltip]="favorite.name">
            <a *ngIf="favorite.href" href="{{favorite.href}}" target="{{favorite.target}}"><i class="fa fa-{{favorite.icon}}"></i></a>
            <a *ngIf="favorite.routerLink" [routerLink]="favorite.routerLink"><i class="fa fa-{{favorite.icon}}"></i></a>
        </li>
    </ul>    
    <ss-multiselect-dropdown id="favorites" class="custom-multiselect"
        [options]="favoriteOptions" 
        [texts]="favoriteTexts" 
        [settings]="favoriteSettings"
        (dropdownOpened)="onDropdownOpened()"
        (dropdownClosed)="onDropdownClosed()" 
        [(ngModel)]="favoriteModel"
        (ngModelChange)="onChange()">
    </ss-multiselect-dropdown>
</div>
