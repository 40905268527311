import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router'
import { GeneralService } from '../../pages/Services/general.service'

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  name: any;
  number: any;
  subject: any;
  message: any;
  email: any;
  show: boolean = false
  contactForm: FormGroup;
  constructor(fb: FormBuilder, public generalService: GeneralService, public router: Router) {
    //this.contactForm = fb.group({
    //  name: ['',],
    //  email: ['',],
    //  number: ['',],
    //  subject: ['',],
    //  message: ['',]
    //});

  }

  ngOnInit(): void {
  }

  SendMessage(name, email, number, subject, message) {
    debugger
    this.show = true;
    if (email == undefined || email == '' || email == null) {
      this.generalService.ShowAlert('INFO', 'All fields mandatory', 'info')
    }
    else {
      let arr = [];
      arr.push({
        PersonName: this.name,
        PersonEmail: this.email,
        PersonMobile: this.number,
        Comments: this.message
      })
      var url = "/api/Product/SendContatEnquirydetailstoadmin";
      debugger
      // this.spinner.show();
      var UploadFile = new FormData();
      UploadFile.append("userdata", JSON.stringify(arr));
      this.generalService.PostData(url, UploadFile).then((data: any) => {
        debugger
        if (data == "Sucess") {
          this.generalService.ShowAlert('SUCCESS', 'The form was sent successfully.', 'success')
          this.name = '';
          this.email = '';
          this.subject = '';
          this.message = '';
          this.number = '';
        }
        //this.Products = data;

        // this.spinner.hide();
      }, err => {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        // this.spinner.hide();
      });
    }
  }

}
