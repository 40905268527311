import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../pages/Services/general.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-page-footer',
  templateUrl: './page-footer.component.html',
  styleUrls: ['./page-footer.component.scss']
})
export class PageFooterComponent implements OnInit {

  constructor(public generalService: GeneralService, public router: Router) { }

  ngOnInit(): void {
  }
  FilterList(id) {
    debugger

    localStorage.removeItem('catid');
    localStorage.setItem("catid", id);
    var route = this.router.url
    if (route == '/Products') {
      window.location.reload();
    }
    else {
      this.router.navigateByUrl('/Products');

    }
  }

}
