<div class="labto-nav navbar-area is-sticky">
  <div class="container">
    <div class="row">

      <div class="col-lg-2 col-sm-2 logo"><a routerLink="/Home"><img src="https://www.klsindia.com/images/kls_logo.png" class="logo" style="width: 125px;" alt="logo"></a></div>
      <div class="col-lg-7 col-sm-7 padding_0">
        <nav class="navbar navbar-expand-md navbar-light">
          <!--<a class="navbar-brand" href="index.html" style="width: 185px;"><img src="../../../assets/img/website/logo.png" class="logo" alt="logo"></a>-->
          <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent" style="display: block;">
            <div class="navbar navbar-expand-md navbar-dark bg-dark mb-4" role="navigation">

              <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <!--<span class="navbar-toggler-icon"></span>-->
                <img src="https://img.icons8.com/windows/32/000000/menu--v1.png" />
              </button>
              <div class="collapse navbar-collapse" id="navbarCollapse">
                <ul class="navbar-nav mr-auto">
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': selectedItem == 'Home'}" (click)="listClick('Home')" routerLink="/Home">Home <span class="sr-only">(current)</span></a>
                  </li>
                  <!--<li class="nav-item">-->
                  <!--<a class="nav-link" [ngClass]="{'active': selectedItem == 'About Us'}" (click)="listClick('About Us')" routerLink="/about-us">About Us</a>-->
                  <li class="nav-item ">
                    <a class="nav-link " [ngClass]="{'active': selectedItem == 'About Us'}" (click)="listClick('About Us')" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" routerLink="/about-us">About Us </a>
                    <!--<ul class="dropdown-menu" aria-labelledby="dropdown1" style="font-size:14px">
                      <li class="dropdown-item"> Mission</li>
                      <li class="dropdown-item"> Vision</li>
                      <li class="dropdown-item"> Infrastructure</li>
                    </ul>-->
                  </li>
                  <!--</li>-->
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" [ngClass]="{'active': selectedItem == 'Products'}" (click)="listClick('Products')" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Products</a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown1" style="font-size:14px">
                      <li class="dropdown-item" *ngFor="let cat of categories" (click)="FilterList(cat.CategoryID)"> {{cat.CategoryName}}</li>

                    </ul>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': selectedItem == 'Facilities'}" (click)="listClick('Facilities')" routerLink="/TechSupport">Tech Support </a>
                  </li>
                  <!--<li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': selectedItem == 'Clients'}" (click)="listClick('Clients')" routerLink="/">Infrastructure </a>
                  </li>-->
                  <li class="nav-item">
                    <a class="nav-link" [ngClass]="{'active': selectedItem == 'Careers'}" (click)="listClick('Careers')" routerLink="/careers">Careers </a>
                  </li>
                  <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle" [ngClass]="{'active': selectedItem == 'Contact'}" (click)="listClick('Contact')" id="dropdown1" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Contact Us </a>
                    <ul class="dropdown-menu" aria-labelledby="dropdown1" style="font-size:14px">

                      <li class="dropdown-item" routerLink="/contact-us"> Reach Us</li>

                      <!--<li class="dropdown-item"> Route Map</li>-->

                      <li class="dropdown-item" routerLink="/News"> News & Events</li>
                      <li class="dropdown-item" routerLink="/photogallery">  Photo Gallery</li>

                    </ul>
                  </li>

                </ul>

              </div>
            </div>
            <!--<div class="others-options disply_n">
              <div class="input-group">
                <input type="hidden" name="search_param" value="all" id="search_param">
                <input type="text" class="form-control" name="x" placeholder="Search Product Name...">

              </div>
            </div>-->
          </div>
        </nav>
      </div>
      <div class="col-lg-3 col-sm-3 search_h">

        <p-autoComplete inputStyleClass="inputTest" panelStyleClass="panelTest" [suggestions]="filteredSpecifications" (completeMethod)="filterSpecifications($event)"  [(ngModel)]="autoCompleteText" field="CatChemicalName" placeholder="Search with Product Name">         
            <ng-template let-specdata pTemplate="item" (click)="GetSpecdata(specdata)">
              <span (click)="GetSpecdata(specdata)">
                {{specdata.CatChemicalName}}
              </span>
            </ng-template>
        </p-autoComplete>


        <!--<div class="others-options disply_n">
          <div class="input-group">
            <input type="hidden" name="search_param" value="all" id="search_param">
            <input type="text" style="margin-top:20px" class="form-control" name="x" placeholder="Search Product Name...">

          </div>
        </div>-->
      </div>

    </div>
  </div>

</div>


<script>
  $('.navbar-nav>li>a').on('click', function () {
    $('.navbar-collapse').collapse('hide');
  });
</script>
