<div class="container bred_border">
  <div class="row page_mar_t">
    <div class="col-lg-10 col-md-10 col-sm-12 col-xs-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" style="cursor:pointer"><a routerLink="/Home">Home</a></li>
          <li class="breadcrumb-item bread_active" aria-current="page">Photo Gallery</li>
        </ol>
      </nav>
    </div>
  </div>
</div>
<br/>
<div class="container">
  <div class="row">
    <div class="column">
      <img src="../../../assets/img/photogallery/b1.jpg" style="width:100%;" (onclick)="openModal();currentSlide(1)" class="hover-shadow cursor">
    </div>
    <div class="column">   
      <img src="../../../assets/img/photogallery/b2.jpg" style="width:100%" (onclick)="openModal();currentSlide(2)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b3.jpg" style="width:100%;" (onclick)="openModal();currentSlide(3)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b4.jpg" style="width:100%;" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b6.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b7.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b8.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b9.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b1.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b10.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b11.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b12.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b13.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
    <div class="column">
      <img src="../../../assets/img/photogallery/b14.jpg" style="width:100%" (onclick)="openModal();currentSlide(4)" class="hover-shadow cursor">
    </div>
  </div>
</div>

<div id="myModal" class="modal">
  <span class="close cursor" (onclick)="closeModal()">&times;</span>
  <div class="modal-content">

    <div class="mySlides">
      <div class="numbertext">1 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>

    <div class="mySlides">
      <div class="numbertext">2 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>

    <div class="mySlides">
      <div class="numbertext">3 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>

    <div class="mySlides">
      <div class="numbertext">4 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">5 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">6 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">7 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">8 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">9 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">10 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">11 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">12 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">13 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>
    <div class="mySlides">
      <div class="numbertext">14 / 14</div>
      <img src="../../../assets/img/" style="width:100%">
    </div>

    <a class="prev" (onclick)="plusSlides(-1)">&#10094;</a>
    <a class="next" (onclick)="plusSlides(1)">&#10095;</a>

    <div class="caption-container">
      <p id="caption"></p>
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/website/bg_1.jpg" style="width:100%" />
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/website/bg_1.jpg" style="width:100%" alt="Snow">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" alt="Mountains and fjords">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>
    <div class="column">
      <img class="demo cursor" src="../../../assets/img/" style="width:100%" (onclick)="currentSlide(4)" alt="Northern Lights">
    </div>

  </div>
</div>

<script>
  function openModal() {
    document.getElementById("myModal").style.display = "block";
  }

  function closeModal() {
    document.getElementById("myModal").style.display = "none";
  }

  var slideIndex = 1;
  showSlides(slideIndex);

  function plusSlides(n) {
    showSlides(slideIndex += n);
  }

  function currentSlide(n) {
    showSlides(slideIndex = n);
  }

  function showSlides(n) {
    var i;
    var slides = document.getElementsByClassName("mySlides");
    var dots = document.getElementsByClassName("demo");
    var captionText = document.getElementById("caption");
    if (n > slides.length) { slideIndex = 1 }
    if (n < 1) { slideIndex = slides.length }
    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }
    for (i = 0; i < dots.length; i++) {
      dots[i].className = dots[i].className.replace(" active", "");
    }
    slides[slideIndex - 1].style.display = "block";
    dots[slideIndex - 1].className += " active";
    captionText.innerHTML = dots[slideIndex - 1].alt;
  }
</script>

<!--</body>
</html>-->
