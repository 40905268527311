<footer class="bg-light text-center text-lg-start">

  <!-- Grid container -->
  <div class="container p-4">
    <!--Grid row-->
    <div class="row">
      <!--Grid column-->
      <div class="col-lg-4 col-md-4 mb-4 mb-md-0" style="text-align: left;">
        <img src="https://www.klsindia.com/images/kls_logo.png" style="width: 122px;" />
        <div class="footer_text">
          Kamineni Life Sciences Private Limited, an ISO 9001:2015 & 13485:2016 Certified Company is a renowned manufacturer of Diagnostic. Our range of quality Diagnostic Kits and Medical Equipments includes reagents, rapids, Elisa, medical instruments, and other products. Our State-of-the-art infrastructure is as per WHO-GMP certified.
        </div>
      </div>
      <!--Grid column-->
      <!--Grid column-->
      <div class="col-lg-2 col-md-2 mb-4 mb-md-0 margin_t_2">
        <h5 class="text-uppercase mb-0">USEFUL LINKS</h5>

        <ul class="list-unstyled">
          <li>
            <a routerLink="/about-us" class="text-dark">About Us</a>
          </li>
          <li>
            <a routerLink="/Products" class="text-dark">Products</a>
          </li>
          <li>
            <a routerLink="/TechSupport" class="text-dark">Tech Support</a>
          </li>
          <li>
            <a routerLink="/News" class="text-dark">News</a>
          </li>
          <!--<li>
            <a routerLink="#" class="text-dark">Site Map</a>
          </li>-->
          <li>
            <a routerLink="/login" class="text-dark">Admin Login</a>
          </li>
        </ul>
      </div>
      <!--Grid column-->
      <!--Grid column-->
      <div class="col-lg-2 col-md-2 mb-4 mb-md-0 margin_t_2">
        <h5 class="text-uppercase">OUR PRODUCTS</h5>

        <ul class="list-unstyled mb-0">
          <li>
            <a style="cursor:pointer!important" (click)="FilterList(1)" class="text-dark">Lifeband - HIV Cards</a>
          </li>
          <li>
            <a style="cursor:pointer!important" (click)="FilterList(2)" class="text-dark">Lifeband - HBsAg Cards</a>
          </li>
          <li>
            <a style="cursor:pointer!important" (click)="FilterList(3)" class="text-dark">Lifeband (2P & 10P)</a>
          </li>
          <li>
            <a style="cursor:pointer!important" (click)="FilterList(3)" class="text-dark">Lifeband - hCG Cards</a>
          </li>
        </ul>
      </div>
      <!--Grid column-->
      <!--Grid column-->
      <div class="col-lg-4 col-md-4 mb-4 mb-md-0 margin_t_2">
        <h5 class="text-uppercase mb-0">CONTACT INFO</h5>
        <span class="text-dark">
          <a routerLink="/contact-us" class="text-dark">
          <!--<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d60908.1803518895!2d78.48052167042965!3d17.423240642517527!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bcb9b8f813aaf0d%3A0xfbff4fbac1d9d657!2sKamini+Life+Sciences+Private+Limited!5e0!3m2!1sen!2sin!4v1563522529853!5m2!1sen!2sin" width="700" height="400" frameborder="0" style="border:0" allowfullscreen=""></iframe>-->
          <img routerLink="/contact-us" style="cursor:pointer" src="https://img.icons8.com/android/15/000000/place-marker.png" />
          Kamineni Life Sciences Manufacturing facility, R & D centre, Corporate HQ's Unit D4-7, Industrial Estates, Moula-Ali, Hyderabad – 500040, Telangana, India
          <!--Unit D4-7, Industrial Estates, Moula-Ali,
  Hyderabad – 500040, Telangana, India,-->
            </a>
        </span>
        <ul class="list-unstyled">

          <li>
            <a href="tel:07569041276" class="text-dark"> <img src="https://img.icons8.com/android/15/000000/outgoing-call.png" />  +91 7569041276</a>
            <a href="tel:09182562061" class="text-dark"> <img src="https://img.icons8.com/android/15/000000/outgoing-call.png" />  +91 9182562061</a>
          </li>
          <li>
            <!--<a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=mailto:info@nchemlaboratories.com" target="_blank">Title</a>-->

            <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=mailto:enquiry@klsindia.com" class="text-dark"> <img src="https://img.icons8.com/material/15/000000/mail.png" /> enquiry@klsindia.com </a>
          </li>
          <li>

            <a href="https://mail.google.com/mail/?view=cm&fs=1&tf=1&to=mailto:ceo@klsindia.com " class="text-dark"> <img src="https://img.icons8.com/material/15/000000/mail.png" /> ceo@klsindia.com </a>
          </li>

        </ul>
      </div>
      <!--Grid column-->
    </div>
    <!--Grid row-->
  </div>
  <!-- Grid container -->

  <div class="container">
    <div class="row" style="padding-top:1rem;">
      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12" style="text-align: left;">
        <p>Copyright © 2021 All Rights Reserved by Kamineni Life Sciences</p>
      </div>

      <div class="col-lg-6 col-md-6 col-sm-12 col-xs-12">
        <p style="text-align:right">
          <a href="https://gagri.net/" target="_blank"> Powered By -  www.gagri.net </a>
        </p>
          </div>
      <!--<div class="col-lg-3 col-md-3 col-sm-12 col-xs-12">
        <ul style="display: flex;list-style-type: none;" class="s_icons">
          <li style="padding: 0 10px 0 0px;cursor:pointer;"> <a href="https://www.facebook.com/" target="_blank"><img src="https://img.icons8.com/material-outlined/24/3b5998/facebook-new.png" /></a></li>
          <li style="padding: 0 10px 0 0px;cursor:pointer;"> <a href="https://www.youtube.com/" target="_blank"><img src="https://img.icons8.com/material-outlined/24/FF0000/youtube-play--v1.png" /></a></li>
          <li style="padding: 0 10px 0 0px;cursor:pointer;"> <a href="https://www.twitter.com/" target="_blank"><img src="https://img.icons8.com/material-outlined/24/1DA1F2/twitter-squared.png" /></a></li>
          <li style="padding: 0 10px 0 0px;cursor:pointer;"> <a href="https://www.linkedin.com/" target="_blank"><img src="https://img.icons8.com/material-outlined/24/0077b5/linkedin.png" /></a></li>
        </ul>
      </div>-->
    </div>
  </div>

</footer>
