import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from '../../pages/Services/general.service'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'
import { Directive, HostListener, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
declare var $: any;
@Component({
  selector: 'app-productinfo',
  templateUrl: './productinfo.component.html',
  styleUrls: ['./productinfo.component.scss']
})
export class ProductinfoComponent implements OnInit {
  image: any;
  whatsApphomeURL: string;
  categories: any=[];
  cartData = [];
  myThumbnail = "https://wittlock.github.io/ngx-image-zoom/assets/thumb.jpg";
  myFullresImage = "https://wittlock.github.io/ngx-image-zoom/assets/fullres.jpg";
  ProductData = [];
  CatlogID: any;
  Specdata = []
  CatID: any;
  CategoryName: any;
    HomeURL: any;
    Uimages: any;
    ProdId: any;
  cartitems = false;
    Image1: any;
    slides: HTMLCollectionOf<Element>;
    dots: HTMLCollectionOf<Element>;
  slideIndex: number;
  responsiveOptions: any[] = [
    {
       breakpoint: '1024px',
       numVisible: 5
    },
    {
       breakpoint: '768px',
       numVisible: 3
    },
    {
      breakpoint: '560px',
      numVisible: 1
    }
  ];
 

  constructor(public generalService: GeneralService, public router: Router, public http: HttpClient) {
    debugger
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      debugger
      this.HomeURL = data.Webservice;
    });

    this.CatID = localStorage.getItem('Cattegoryid');
    this.CatlogID = localStorage.getItem('CatlogID');
    this.cartData = JSON.parse(localStorage.getItem('cartData'));
    this.Specdata = [];
    this.Specdata.push(JSON.parse(localStorage.getItem('ProdDetails')));
    this.CategoryName = this.Specdata[0].CategoryName;
    //console.log(this.cartData)
    this.ProdId = this.Specdata[0].P_ID;
    this.GetProductImages(this.ProdId);

  }



  ngOnInit(): void {
    this.getProductCategories();
   // this.GetSpecificationdata(this.CatlogID)
    this.ProductData = [];
  }

  GetProductImages(prodid) {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("ProdId", prodid);
    var url = "/api/Product/GetProductImagesBasedOnProdId";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      this.Uimages = data;
      this.Image1 = this.Uimages[0].ProductImage;
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }

  GetSpecificationdata(Catalog_No) {
    var url = "/api/Product/GetProductSpecsUsing_Catlog";
    debugger
    // this.spinner.show();
    var UploadFile = new FormData();
    UploadFile.append("Catlog_No", this.CatlogID);
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        this.Specdata = data.Specification;
          
      }
      // this.spinner.hide();
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      // this.spinner.hide();
    });
  }
  back() {
    this.cartData = [];
    this.router.navigateByUrl('/Products');
  }

  sendWhatsapp(item) {
    debugger
    //this.facebookshareurl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(document.URL);
    var str = item.ChemicalName.replace(/\s/g, '')
    this.whatsApphomeURL = "Hi Kamineni life sciences below linked product for enquiry       " + "https://kls.breakingindiaapp.com/%23/products-info/" + item.P_ID + "/" + str
    window.open("https://api.whatsapp.com/send?phone=917569041276&text=" + this.whatsApphomeURL)
  }

  addtoEnquiry(item) {
    debugger
    if (this.cartData.length == 0) {
      this.generalService.ShowAlert('WARNING', 'Please select the size', 'warning')
    }
    else {
      //var j = 1
      //var k = 0
      //if (this.cartData.length == 0) {
      // // this.cartData.push({ P_ID: this.CatlogID, ChemicalName: cName, Cas: cas, Qty: j })
      //  this.cartData.push({ P_ID: item.P_ID,  Catalog_No: item.Catalog_No, ChemicalName: item.ChemicalName, Cas: item.Cas, Qty: j, Pack1: item.Pack1 })

      //}
      //else {
      //  for (var i = 0; i < this.cartData.length; i++) {
      //    if (this.cartData[i].P_ID == item.P_ID) {
      //      this.cartData[i].Qty = this.cartData[i].Qty + 1;
      //      k++;
      //    }

      //  }
      //  if (k == 0) {
      //  //  this.cartData.push({ P_ID: this.CatlogID, ChemicalName: cName, Cas: cas, Qty: j })
      //    this.cartData.push({ P_ID: item.P_ID,Catalog_No: item.Catalog_No, ChemicalName: item.ChemicalName, Cas: item.Cas, Qty: j, Pack1: item.Pack1 })

      //  }
      //}
      localStorage.setItem("cartData", JSON.stringify(this.cartData))
      this.router.navigateByUrl('/Products');
    }
  }

  FilterList(id) {
    localStorage.removeItem('catid');
    localStorage.setItem("catid", this.CatID);
    this.router.navigateByUrl('/Products');
  }
  addToCart(item, p) {
    debugger
    this.cartitems = true;
    var j = 1
    var k = 0
    if (this.cartData.length == 0) {
      this.cartData.push({
        P_ID: item.P_ID,
        Catalog_No: item.Catalog_No,
        ChemicalName: item.ChemicalName,
        Cas: item.Cas, Qty: j,
        Pack: p
      })
    }
    else {
      for (var i = 0; i < this.cartData.length; i++) {
        if (this.cartData[i].P_ID == item.P_ID && this.cartData[i].Pack == p) {
          this.cartData[i].Qty = this.cartData[i].Qty + 1;
          k++;
        }
      }
      if (k == 0) {
        //this.cartData.push({ P_ID: item.P_ID, Catalog_No: item.Catalog_No, ChemicalName: item.ChemicalName, Cas: item.Cas, Qty: j, Pack1: item.Pack1 })
        this.cartData.push({
          P_ID: item.P_ID,
          Catalog_No: item.Catalog_No,
          ChemicalName: item.ChemicalName,
          Cas: item.Cas,
          Qty: j,
          Pack: p
        })

      }
    }
  }
  getProductCategories() {
    var url = "api/Values/getProductCategories";
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.categories = data;
    });
  }

  /*Below show modal method is fired when use clicks the product image
  Note:=>It will get the image path from html code into this.image it will bind to modal dialog box
  */
  showModal(img) {
    this.image = img;
    $('#myModal22').modal('show');

  }

  
  imageZoom(imgID, resultID) {
    
    var img, lens, result, cx, cy;
    img = document.getElementById(imgID);
    result = document.getElementById(resultID);
    lens = document.createElement("DIV");
    lens.setAttribute("class", "img-zoom-lens");
    img.parentElement.insertBefore(lens, img);
    cx = result.offsetWidth / lens.offsetWidth;
    cy = result.offsetHeight / lens.offsetHeight;
    result.style.backgroundImage = "url('" + img.src + "')";
    result.style.backgroundSize = (img.width * cx) + "px " + (img.height * cy) + "px";
    lens.addEventListener("mousemove", moveLens);
    img.addEventListener("mousemove", moveLens);
    lens.addEventListener("touchmove", moveLens);
    img.addEventListener("touchmove", moveLens);
    function moveLens(e) {
      var pos, x, y;
      e.preventDefault();
      pos = getCursorPos(e);
      x = pos.x - (lens.offsetWidth / 2);
      y = pos.y - (lens.offsetHeight / 2);
      if (x > img.width - lens.offsetWidth) { x = img.width - lens.offsetWidth; }
      if (x < 0) { x = 0; }
      if (y > img.height - lens.offsetHeight) { y = img.height - lens.offsetHeight; }
      if (y < 0) { y = 0; }
      lens.style.left = x + "px";
      lens.style.top = y + "px";
      result.style.backgroundPosition = "-" + (x * cx) + "px -" + (y * cy) + "px";
    }
    function getCursorPos(e) {
      var a, x = 0, y = 0;
      e = e || window.event;
      a = img.getBoundingClientRect();
      x = e.pageX - a.left;
      y = e.pageY - a.top;
      x = x - window.pageXOffset;
      y = y - window.pageYOffset;
      return { x: x, y: y };
    }


    //  this.GetFabricBYIdAndCategoryId();
  }  
  
}
