import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router'; 
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { BrowserModule } from '@angular/platform-browser';
import { LocationStrategy, Location, PathLocationStrategy } from '@angular/common';
import { DummyComponent } from './pages/dummy/dummy.component';
import { PageLayoutComponent } from './page-layout/page-layout.component';
import { PageFooterComponent } from './page-layout/page-footer/page-footer.component';
import { PageHeaderComponent } from './page-layout/page-header/page-header.component';
import { HomeComponent } from './page-layout/home/home.component';
import { ProductsComponent } from './page-layout/products/products.component';
import { ProductinfoComponent } from './page-layout/productinfo/productinfo.component';
import { AboutUsComponent } from './page-layout/about-us/about-us.component';
import { CareersComponent } from './page-layout/careers/careers.component';
import { ContactUsComponent } from './page-layout/contact-us/contact-us.component';
import { ClientsComponent } from './page-layout/clients/clients.component';
import { TechSupportComponent } from './page-layout/tech-support/tech-support.component';
import { PhotogalleryComponent } from './page-layout/photogallery/photogallery.component';
import { NewsComponent } from './page-layout/news/news.component';
//import { TestComponent } from './page-layout/test/test.component'


export const routes: Routes = [

  { path: '', redirectTo: 'Home', pathMatch: 'full' },
  //{ path: '', redirectTo: 'Products', pathMatch: 'full' },
  {
    path: '',
    component: PageLayoutComponent,
    children: [
      { path: 'Home', component: HomeComponent },
     // { path: 'test', component: TestComponent },

      { path: 'PageHeader', component: PageHeaderComponent },
      { path: 'PageFooter', component: PageFooterComponent },
      { path: 'Products', component: ProductsComponent },
      { path: 'products-info/:ID/:ProductName', component: ProductinfoComponent },
      { path: 'contact-us', component: ContactUsComponent },
      { path: 'about-us', component: AboutUsComponent },
      { path: 'clients', component: ClientsComponent },
      { path: 'careers', component: CareersComponent },
      { path: 'TechSupport', component: TechSupportComponent },
      { path: 'photogallery', component: PhotogalleryComponent },
      { path: 'News', component: NewsComponent },
    ]
  },


  {
    path: '', 
    component: PagesComponent,
    children:[
      { path: 'Dashboard', loadChildren: () => import('./pages/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' }  },          
      { path: 'dynamic-menu', loadChildren: () => import('./pages/dynamic-menu/dynamic-menu.module').then(m => m.DynamicMenuModule), data: { breadcrumb: 'Dynamic Menu' }  },  
      { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
      { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } },
      { path: 'Admin', loadChildren: () => import('./pages/admin/admin.module').then(m => m.AdminModule) },

      ]
  },
 
  { path: 'login', loadChildren: () => import('./pages/login/login.module').then(m => m.LoginModule) },
  { path: 'register', loadChildren: () => import('./pages/register/register.module').then(m => m.RegisterModule) },
  { path: 'dummy', component: DummyComponent },

  { path: '**', component: NotFoundComponent },
]; 

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { useHash: true })
  ],
  //providers: [Location, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  exports: [
    RouterModule, BrowserModule
  ]
})
export class AppRoutingModule { }
