import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photogallery',
  templateUrl: './photogallery.component.html',
  styleUrls: ['./photogallery.component.scss']
})

export class PhotogalleryComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }
  currentSlide(id) {
    debugger
  }
  openModal() {

  }
  closeModal() {

  }
  plusSlides(id) {

  }
}
