import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { GeneralService } from '../../pages/Services/general.service'
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router'
import { Directive, HostListener, Input } from '@angular/core'
import { ActivatedRoute } from '@angular/router';
import { EmailValidators } from 'ngx-validators'

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {
  whatsApp: any;
  whatsApphomeURL: any;
  categories: any=[];

  alphabetvale: any;
  enquirydata: any;
  categoryId: any;
  ProductListforFilter: any;
  ProductsList: any;
  public form: FormGroup;
  personName: any;
  contactNumber: any;
  email: any;
  comments: any;
  cartitems = false;
  cartData = [];
  enquiryData = [];
  pInfocartData: any
  CategoryName: any
  Uimages: any;
  HomeURL: any;
    Image1: any;


  constructor(fb: FormBuilder, public generalService: GeneralService, public http: HttpClient, public router: Router) {
    debugger
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      debugger
      this.HomeURL = data.Webservice;
      this.whatsApp = data;
    });
    debugger
    this.pInfocartData = JSON.parse(localStorage.getItem('cartData'))
    this.enquirydata = JSON.parse(localStorage.getItem('enquiryData'))
    if (this.pInfocartData != null) {
      this.cartData = this.pInfocartData
    }

    this.form = fb.group({
      'personName': ['', Validators.compose([Validators.required])],
      'contactNumber': ['', Validators.compose([Validators.required])],
      'comments': [''],
      'email': ['', Validators.compose([EmailValidators.normal])],
    });
    this.personName = this.form.controls['personName'];
    this.contactNumber = this.form.controls['contactNumber'];
    this.comments = this.form.controls['comments'];
    this.email = this.form.controls['email'];
    if (this.enquirydata != null) {
      this.form.controls['personName'].setValue(this.enquirydata[0].PersonName);
      this.form.controls['contactNumber'].setValue(this.enquirydata[0].PhoneNumber);
      this.form.controls['email'].setValue(this.enquirydata[0].MailId);
    }
    console.log(this.pInfocartData)

    var catdata = localStorage.getItem('catid')
    if (catdata != null) {
      this.categoryId = catdata
      this.FilterList(catdata);

    }
    else {
      this.categoryId = 1;
      this.FilterList(1);
    }
    
    localStorage.removeItem('catid');
    
  }

  ngOnInit(): void {
    
  }
  FilterList(id) {
    debugger
    this.categoryId = id
      this.GetProductsBasedOnCategoryID(id)
  }
  GetProductImages(prodid) {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("ProdId", prodid);
    var url = "/api/Product/GetProductImagesBasedOnProdId";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      this.Uimages = data;
      this.Image1 = this.Uimages[0].ProductImage;
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
    });
  }
  /*end code*/

  GetProductsBasedOnCategoryID(Id) {
    debugger
    var CategoryId = Id
    var UploadFile = new FormData();
    UploadFile.append("CategoryID", CategoryId);
    var url = "/api/Product/GettingProductDetailsBasedOnCategoryID"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      this.ProductsList = data
      //this.GetProductImages(data[0].P_ID)
      this.ProductListforFilter = data
      this.getProductCategories();  
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      // this.spinner.hide();
    });
  }

  ProductInfo(item) {
    debugger
    localStorage.removeItem('Cattegoryid');
    localStorage.removeItem('CatlogID');
    localStorage.setItem("CatlogID", item.Catalog_No)
    localStorage.setItem("cartData", JSON.stringify(this.cartData))
    localStorage.removeItem('ProdDetails');
    localStorage.setItem("ProdDetails", JSON.stringify(item))
    localStorage.setItem("Cattegoryid", this.categoryId);
    //this.router.navigateByUrl('/products-info');
    this.router.navigate(['/products-info', item.P_ID, item.ChemicalName]);
  }
  addToCart1(item) {
    debugger
    this.cartitems = true;
    var j = 1
    var k = 0
    if (this.cartData.length == 0) {
      this.cartData.push({
        P_ID: item.P_ID,
        Catalog_No: item.Catalog_No,
        ChemicalName: item.ChemicalName,
        Cas: item.Cas,
        Qty: j,
        Pack1: item.Pack1
      })
    }
    else {
      for (var i = 0; i < this.cartData.length; i++) {
        if (this.cartData[i].P_ID == item.P_ID) {
          this.cartData[i].Qty = this.cartData[i].Qty + 1;
          k++;
        }
      }
      if (k == 0) {
        this.cartData.push({
          P_ID: item.P_ID,
          Catalog_No: item.Catalog_No,
          ChemicalName: item.ChemicalName,
          Cas: item.Cas, Qty: j,
          Pack1: item.Pack1
        })
      }
    }
  }

  addToCart(item,p) {
    debugger
    this.cartitems = true;
    var j = 1
    var k = 0
    if (this.cartData.length == 0) {
      this.cartData.push({
        P_ID: item.P_ID,
        Catalog_No: item.Catalog_No,
        ChemicalName: item.ChemicalName,
        Cas: item.Cas, Qty: j,
        Pack: p
      })
    }
    else {
      for (var i = 0; i < this.cartData.length; i++) {
        if (this.cartData[i].P_ID == item.P_ID && this.cartData[i].Pack ==p) {
          this.cartData[i].Qty = this.cartData[i].Qty + 1;
          k++;
        }
      }
      if (k == 0) {
        //this.cartData.push({ P_ID: item.P_ID, Catalog_No: item.Catalog_No, ChemicalName: item.ChemicalName, Cas: item.Cas, Qty: j, Pack1: item.Pack1 })
        this.cartData.push({
          P_ID: item.P_ID,
          Catalog_No: item.Catalog_No,
          ChemicalName: item.ChemicalName,
          Cas: item.Cas,
          Qty: j,
          Pack: p
        })

      }
    }
  }

  sendWhatsapp(item) {
    debugger
    //this.facebookshareurl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(document.URL);
    var str = item.ChemicalName.replace(/\s/g, '')
    this.whatsApphomeURL ="Hi Kamineni life sciences below linked product for enquiry       "+"https://kls.breakingindiaapp.com/%23/products-info/" + item.P_ID + "/" + str
    window.open("https://api.whatsapp.com/send?phone=917569041276&text="+ this.whatsApphomeURL)
  }

  sendFacebook(item) {
    debugger
    var facebookShareUrl = 'https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(document.URL);
    var str = item.ChemicalName.replace(/\s/g, '')
    this.whatsApphomeURL = "Hi Kamineni life sciences below linked product for enquiry       " + "https://kls.breakingindiaapp.com/%23/products-info/" + item.P_ID + "/" + str
    //window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(facebookShareUrl) + '&t=' + encodeURIComponent(this.whatsApphomeURL));
    window.open('https://www.facebook.com/sharer/sharer.php?u=' + encodeURIComponent(facebookShareUrl) + '&t=' + encodeURIComponent(this.whatsApphomeURL));

  }



  //removeFromCart(item) {
  //  debugger
  //  this.cartData.splice(item,1)
  //}
  removeFromCart(item) {
    debugger

    this.cartData.splice(item, 1)
    localStorage.removeItem('cartData')
    localStorage.setItem('cartData', JSON.stringify(this.cartData))


    //if (this.cartData[item].Qty > 1) {
    //  this.cartData[item].Qty = this.cartData[item].Qty - 1;
    //}
    //else {
    //  this.cartData.splice(item, 1)
    //  localStorage.removeItem('cartData')
    //  localStorage.setItem('cartData', JSON.stringify(this.cartData))
    //}
  }
  sendEnquiry(value) {
    debugger
    if (this.form.valid) {

      this.enquiryData.push({
        PersonName: value.personName,
        PersonMobile: value.contactNumber,
        PersonEmail: value.email,
        Comments: value.comments
      });
      

      if (this.cartData.length != 0) {
        localStorage.setItem("enquiryData", JSON.stringify(this.enquiryData))
        var UploadFile = new FormData();
        UploadFile.append("userdata", JSON.stringify(this.enquiryData));
        UploadFile.append("cartdata", JSON.stringify(this.cartData));
        UploadFile.append("ID", "1");
        var url = "/api/Product/SendEnquiryDetails";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
          debugger
          if (data != null) {
            if (data.MSG == "Sucess") {
              this.generalService.ShowAlert('SUCCESS', 'Your enquiry has been submitted successfully; please wait for a response from the administrator.', 'success')
              localStorage.removeItem('cartData')
              this.enquiryData[0].EnquiryID= data.datetime;
             // this.FilterList(1);
              //this.form.reset();
              this.InsertEnquiryDetails(this.enquiryData, this.cartData)
              
            }
            else {
              this.generalService.ShowAlert('WARNING', 'something went wrong.', 'warning')
              this.enquiryData = [];
            }
          }

        }, error => ({
          title: 'ERROR',
          text: 'Something went wrong. Please try again later.',
          type: 'error',
          confirmButtonText: 'Ok'

        })

        );
      }



    }
  }

  enquirymailtoadmin(data, data1) {
    var CategoryId = this.categoryId
    var UploadFile = new FormData();
    UploadFile.append("userdata", JSON.stringify(data));
    UploadFile.append("cartdata", JSON.stringify(data1));
    UploadFile.append("ID", "2");
    var url = "/api/Product/SendEnquiryDetails";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      if (data == "Sucess") {
        this.FilterList(1);
        this.cartData = [];
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      // this.spinner.hide();
    });
  }

  alphabetFilter(value) {
    debugger
    this.alphabetvale = value
    if (value == 1) {
      this.ProductsList = this.ProductListforFilter
      debugger
    }
    else {
      var CategoryId = this.categoryId
      var UploadFile = new FormData();
      UploadFile.append("Category_Id", CategoryId);
      UploadFile.append("char", value);
      var url = "/api/Product/GetProductsByChar"
      this.generalService.PostData(url, UploadFile).then(data => {
        debugger

        this.ProductsList = data
        debugger
      }, err => {
        this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
        // this.spinner.hide();
      });
      // this.ProductsList = this.ProductListforFilter.filter(p => p.ChemicalName.charAt(0) == value);
    }
  }

  getProductCategories() {
    var url = "api/Values/getProductCategories";
    this.generalService.GetData(url).then((data: any) => {
      debugger
      this.categories = data;
      console.log(this.categories)
      let a = this.categories.filter(a => a.CategoryID == this.categoryId)
      this.CategoryName = a[0].CategoryName
    });
  }


  InsertEnquiryDetails(data, data1) {
    var CategoryId = this.categoryId
    var UploadFile = new FormData();
    UploadFile.append("userdata", JSON.stringify(data));
    UploadFile.append("cartdata", JSON.stringify(data1));
    UploadFile.append("ID", "2");
    var url = "/api/Product/InsertEnquiryDetails";
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      if (data == "SUCCESS") {
        debugger
        // this.FilterList(this.categoryId);
        this.enquiryData = [];
        this.cartData = [];
      }
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      // this.spinner.hide();
    });
  }
}
