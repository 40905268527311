<div class="container bred_border">
  <div class="row page_mar_t">
    <div class="col-lg-8 col-md-8 col-sm-12 col-xs-12">
      <nav aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item" style="cursor:pointer"><a routerLink="/Home">Home</a></li>
          <li class="breadcrumb-item bread_active" aria-current="page">News</li>
        </ol>
      </nav>
      <!--<iframe style="border:none;height:100%;width:130%" src="https://timesofindia.indiatimes.com/"></iframe>-->
     
      <ul class="ulnews">
        <marquee id='scroll_news' direction="up" height="300px" >
          <li class="linews a" *ngFor="let o of RssData?.rss.channel[0].item">
            <a onMouseOver="document.getElementById('scroll_news').stop();" onMouseOut="document.getElementById('scroll_news').start();" href="{{o.link[0]}}" target="_blank">{{o.title[0]}}</a>
            <br />
          </li>
        </marquee>
      </ul>


    </div>
    <div class="col-lg-4 col-lg-4 col-sm-12 col-xs-12">
      <!--<img src="../../../assets/img/photogallery/b11.jpg" width="100%" />-->
    </div>
  </div>
</div>




