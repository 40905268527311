import { Component, OnInit } from '@angular/core';
import { GeneralService } from '../../../app/pages/Services/general.service';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
declare var $;
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  HomeURL: any;
  ProductsList: any;
  Email: any;
  constructor(public generalService: GeneralService, public http: HttpClient,public router: Router) {
    // Sets interval...what is transition slide speed?
    //$('#carouselExampleIndicators').carousel({
    //});
    //$('.carousel').carousel({
    //  pause: "false"
    //});



    $(document).ready(function () {
      jQuery.fn.carousel.Constructor.TRANSITION_DURATION = 2000  // 2 seconds
    });
    this.http.get('../assets/WebService.json').subscribe((data: any) => {
      debugger
      this.HomeURL = data.Webservice;
    });
    

  }

  ngOnInit(): void {
    this.GetProductsBasedOnCategoryID()
  }
  Subscribers(Email) {
    debugger
    var UploadFile = new FormData();
    UploadFile.append("Email", Email);
    var url = "api/Product/AddSubscriberEmails";
    this.generalService.PostData(url, UploadFile).then((data: any) => {
      debugger
      if (data != null) {
        if (data == "SUCCESS") {
          this.generalService.ShowAlert('Success', 'Subscribe successfully', 'Success');
          this.Email = "";
        }
        else {
          this.generalService.ShowAlert('Warning', 'Already Exist', 'Warning')
          this.Email = "";
        }
      }

    }, error => ({
      title: 'ERROR',
      text: 'Something went wrong. Please try again later.',
      type: 'error',
      confirmButtonText: 'Ok'

    })

    );

  }

  GetProductsBasedOnCategoryID() {
    debugger
    var CategoryId = '1'
    var UploadFile = new FormData();
    UploadFile.append("CategoryID", CategoryId);
    var url = "/api/Product/GettingProductDetailsBasedOnCategoryID"
    this.generalService.PostData(url, UploadFile).then(data => {
      debugger
      this.ProductsList = data
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      // this.spinner.hide();
    });
  }

  productInfo(pid) {
    var productID = pid
    var UploadFile = new FormData();
    UploadFile.append("ProductID", productID);
    var url = "/api/Product/GettingProductDetailsBasedOnProductID"
    this.generalService.PostData(url, UploadFile).then(data => {
      this.GetSpecdata(data)      
    }, err => {
      this.generalService.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
      // this.spinner.hide();
    });
  }

  GetSpecdata(filteredSpecifications) {
    debugger
    localStorage.removeItem('Cattegoryid');
    localStorage.removeItem('CatlogID');
    localStorage.setItem("CatlogID", filteredSpecifications[0].Catalog_No)
    localStorage.removeItem('ProdDetails');
    localStorage.setItem("ProdDetails", JSON.stringify(filteredSpecifications[0]))
    localStorage.setItem("Cattegoryid", filteredSpecifications[0].CategoryID);
    var route = this.router.url
    var str = filteredSpecifications[0].ChemicalName.replace(/\s/g, '')
    if (route == '/products-info') {
      localStorage.setItem("Cattegoryid", filteredSpecifications[0].CategoryID);
      window.location.reload();
    }
    else {
      localStorage.setItem("Cattegoryid", filteredSpecifications[0].CategoryID);
      this.router.navigate(['/products-info', filteredSpecifications[0].P_ID, str]);

    }

  }
}
